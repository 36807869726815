<template>
  <div class="pwd">
    <span>当前位置：</span>
    <span @click="toRouter(pwdText)">{{ pwdText }}</span>
    <!-- /
    <span class="current">{{ current }}</span> -->
  </div>
</template>
<script>
export default {
  props: {
    pwdText: String,
    current: String,
  },
  methods: {
    toRouter: function (text) {
      console.log(text);
      switch (text) {
        case "登录":
          this.$router.push({ path: "/login" });
          break;
        case "新闻资讯":
          this.$router.push({ path: "/news" });
          break;
        case "我们的服务":
          this.$router.push({ path: "/service" });
          break;
        case "首页":
          this.$router.push({ path: "/" });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.pwd {
  text-align: left;
  font-size: 14px;
  color: #999999;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.current {
  color: #007db8;
}
</style>