export default {
    data: function () {
        return {
            phone: '',
            code: '',
            step: 1,
            re_password: '',
            password: ''
        }
    },
    created() {
        console.log()
    },
    methods: {
        backIndex: function () {
            this.$router.replace('/')
        },
        nextCode: function () {
            console.log(1231)
            if(this.phone == ''){
                return this.$message('请填写手机号');
            }
            if(this.img_code == ''){
                return this.$message('请填写验证码');
            }
            this.verifyImgCode();
        },
        next: function () {
            this.verifySms();
        },
        setPassword: function () {
            this.modifyPwd()
        },
        modifyPwd: function () {
            var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,18}');
            if(!pwdRegex.test(this.password) || !pwdRegex.test(this.re_password)){
                return this.$message('密码格式不正确');
            }
            let formData = {
                phone: this.phone,
                password: this.password,
                re_password: this.re_password
            }
            this.$post('/api/user/modifyPwd.html', formData).then((res) => {
                if (res.code == 200) {
                    this.step = 1;
                    this.$router.go(-1)
                } else {
                    ;

                }
                return this.$message(res.msg);
            }).finally(() => {

            })
        }
    }
}