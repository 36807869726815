<template>
  <div class="forget-password">
    <div class="c-width">
      <div class="top cf">
        <router-link class="fl" to="/">
          <div class="logo">
            <img :src="logourl" alt="">
          </div>
        </router-link>
        <div class="back-index fr" @click="backIndex">
          <img
            src="../../../assets/images/common_iocn_shouye.png"
            alt=""
            srcset=""
          />
          <span>返回首页</span>
        </div>
      </div>
      <pwd class="pwd" pwdText="登录" current="忘记密码" />
      <div class="form-box" v-if="step == 1">
        <p class="hint">请输入注册时使用的手机号</p>
        <el-input v-model="phone" placeholder="请输入内容"></el-input>
        <p class="hint">验证码</p>
        <div class="code-box cf">
          <el-input
            el-input
            class="code-inp fl"
            v-model="img_code"
            placeholder="请输入内容"
          ></el-input>
          <img
          @click="getImgCode"
            class="code-img fr"
            :src="imgCode"
            alt=""
          />
        </div>
        <div class="next-btn" @click="nextCode">下一步</div>
      </div>
      <div class="form-box" v-if="step == 2">
        <p class="hint">
          已向您的手机发送了一条短信<br />
          请输入验证码：
        </p>
        <el-input v-model="sms_code" placeholder="请输入内容"></el-input>
        <p class="sub-hint">
          验证码24小时内有效，未收到短信？5分钟后可以刷新页面试试 <span>重新发送</span>
        </p>

        <div class="next-btn" @click="next">下一步</div>
      </div>
      <div class="form-box" v-if="step == 3">
        <p class="hint">请输入新密码：</p>
        <p class="sub-hint" style="margin:0 0 20px 0">
          请输入至少两种6-20个数字，字符，字母组合，字母区分大小
        </p>
        <el-input type="password" v-model="password" placeholder="请输入内容"></el-input>
        <p class="hint">请重新输入新密码：</p>
        <el-input  type="password"  v-model="re_password" placeholder="请输入内容"></el-input>
        <div class="next-btn" @click="setPassword">设置新密码</div>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
import pwd from "@/component/pwd/src/pwd";
export default {
  mixins,
  components: {
    pwd,
  },
};
</script>
<style scoped>
@import "../forget-password.css";
</style>