export default{
    data(){
        return{
            logourl:'',
            imgCode:'',
            img_code:'',
            sms_code:'',
        }
    },
    created(){
        this.getLogo();
        this.getImgCode();
    },
    methods:{
        getLogo:function(){
            this.$post('/api/base/getLogo.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                   this.logourl = res.url || ''
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        getImgCode:function(){
            this.imgCode = ''
            let random = (Math.random()+Math.random()+Math.random())*(Math.random()+Math.random()+Math.random());
            this.random = random
            this.$post('/api/user/getImgCode.html', {random:random}).then((res) => {
                if(res.code == 200){
                   this.imgCode = res.data;
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        verifyImgCode:function(){
            let formData={
                phone:this.phone,
                code:this.img_code,
                random:this.random 
            }

            this.$post('/api/user/verifyImgCode.html', formData).then((res) => {
                console.log(res)
                if(res.code == 200){
                    this.getCode()
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        getCode:function(){
            this.$post('/api/user/sendSms.html',{phone:this.phone} ).then((res) => {
                if(res.code == 200){
                    this.step = 2;
                }else{;
                    return this.$message(res.msg); 
                }
       
            }).finally(() => {

            })
        },
        verifySms:function(){
            let formData={
                sms_code:this.sms_code,
                phone:this.phone
            }
            this.$post('/api/user/verifySms.html',formData ).then((res) => {
                if(res.code == 200){
                    this.step = 3;
                }else{;
             
                }
                return this.$message(res.msg); 
            }).finally(() => {

            })
        }
    }
}